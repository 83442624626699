import PointOfInterestType from '../enums/PointOfInterestType';
import SearchItemType from '../enums/SearchItemType';
import SearchOrigin from '../enums/SearchOrigin';
import SearchSuggestionSelectionType from '../enums/SearchSuggestionSelectionType';
import WebSearchTab from '../enums/WebSearchTab';
import { getAmplitudeInstance } from '../amplitudeHelpers';

type logSearchResultClickedParams = {
  click_position?: number;
  content_id?: string;
  item_type?: SearchItemType;
  location: SearchOrigin;
  poi_type?: PointOfInterestType;
  query_length?: number;
  search_tab?: WebSearchTab;
  selection_type: SearchSuggestionSelectionType;
};

/**
 * User clicks a recent search, search suggestion, or nearby item from the search box results.
 * @param params - Attributes to log with the amplitude event
 * @param params.click_position - Zero indexed position in the list of results that the user clicked
 * @param params.content_id - ID of whatever item was selected (be it a trail, place or area)
 * @param params.item_type - Type of item selected (e.g., trail or area)
 * @param params.location - Search origin (e.g., homepage)
 * @param params.poi_type - The point of interest type when a POI is selected, otherwise NULL
 * @param params.query_length - How many characters a user enters into the search box before selecting a drop-down item
 * @param params.search_tab - (Web Only) Search tab under which the result was selected
 * @param params.selection_type - Type of search suggestion that was selected (nearby, suggestion, or recent search)
 */
const logSearchResultClicked = (
  { click_position, content_id, item_type, location, poi_type, query_length, search_tab, selection_type }: logSearchResultClickedParams,
  callback?: () => void
) => {
  getAmplitudeInstance().logEvent(
    'search result clicked',
    {
      'click position': click_position,
      'content id': content_id,
      'item type': item_type,
      location,
      'poi type': poi_type,
      'query length': query_length,
      'search tab': search_tab,
      'selection type': selection_type
    },
    callback
  );
};

export default logSearchResultClicked;

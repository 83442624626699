/**
 * Location in the app where the guide index can be accessed from
 */
enum GuideIndexLocation {
  Guide = 'guide',
  Homepage = 'homepage',
  ParkPage = 'park page',
  Search = 'search'
}

export default GuideIndexLocation;

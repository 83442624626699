import { getAmplitudeInstance } from '../amplitudeHelpers';

/**
 * The 'Login' button is tapped in the global header on web.
 */
const logWebHeaderLoginButtonTapped = (callback?: () => void) => {
  getAmplitudeInstance().logEvent('web header login button tapped', {}, callback);
};

export default logWebHeaderLoginButtonTapped;

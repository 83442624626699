/**
 * The title of the web header in the global nav bar.
 */
enum WebHeaderSection {
  Explore = 'explore',
  MyAccount = 'my account',
  Save = 'save',
  Shop = 'shop'
}

export default WebHeaderSection;

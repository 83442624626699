enum UpsellTrigger {
  AddPhotoToTrail = 'Add Photo to Trail',
  AddTrailToCompletedList = 'Add Trail to Completed List',
  CompleteAuthentication = 'Complete Authentication',
  CopyList = 'Copy List',
  CreateList = 'Create List',
  CreateMap = 'Create Map',
  DirectNav = 'Direct Nav',
  DistanceAway = 'Distance Away',
  DownloadMapLayer = 'Download Map Layer',
  DownloadTheRoute = 'Download the Route',
  EditCalorieInfo = 'Edit Calorie Info',
  EditContactsConnect = 'Edit Contacts Connect',
  EditFacebookConnect = 'Edit Facebook Connect',
  EditGarminConnect = 'Edit Garmin Connect',
  EnableHealthIntegration = 'Enable Health Integration',
  EnableLifeline = 'Enable Lifeline',
  ExploreNullState = 'Explore Null State',
  ExploreProBadge = 'Explore Pro Badge',
  ExploreMapDownloadAreaCTA = 'Explore map download area CTA',
  ExportMapFile = 'Export Map File',
  Favorite = 'Favorite',
  FeatureCardPreviewImage = 'Feature Card Preview Image',
  FeatureCardTrailTour = 'Feature Card Trail Tour',
  Flyover = 'Flyover',
  FollowUser = 'Follow User',
  GalleryTour = 'Gallery Tour',
  GalleryTourParks = 'Gallery Tour Parks',
  GalleryTourTrailCard = 'Gallery Tour Trail Card',
  GlobalNavMenu = 'Global Nav Menu',
  Guide = 'Guide',
  GuideIndex = 'Guide Index',
  HitTheTrailCardDownloadMap = 'Hit the Trail Card Download Map',
  HitTheTrailCardLiveShare = 'Hit the Trail Card Live Share',
  HitTheTrailCardNavigate = 'Hit the Trail Card Navigate',
  HitTheTrailCardOpenMap = 'Hit the Trail Card Open Map',
  HitTheTrailCardOpenInApp = 'Hit the Trail Card Open in App',
  HitTheTrailTrailPageHeaderButton = 'Hit the Trail Trail Page Header Button',
  ListDownloadButtonTapped = 'List Download Button Tapped',
  LiveShare = 'Live Share',
  LiveShareIntroScreen = 'Live Share Intro Screen',
  LocationDirections = 'Location Directions',
  LocationPageAppPromoModule = 'Location page app promo module',
  MapViewDownloadCTA = 'Map View Download CTA',
  NavigateToFeature = 'Navigate to Feature',
  NotificationSettingsLifelineReminders = 'Notification Settings Lifeline Reminders',
  NotificationSettingsOffRouteAlerts = 'Notification Settings Off-Route Alerts',
  OpenDeepLink = 'Open Deep Link',
  POITrailDownload = 'POI Trail Download',
  POITrailSave = 'POI Trail Save',
  POIPageAppPromoModule = 'POI page app promo module',
  ParkDownload = 'Park Download',
  ParkPageAppPromoModule = 'Park page app promo module',
  PlanPage = 'Plan Page',
  PlanYourVisitShowMore = 'Plan Your Visit Show More',
  PrintMap = 'Print Map',
  ProFilters = 'Pro Filters',
  RTTC = 'RTTC',
  RestorePurchases = 'Restore Purchases',
  ReturningUserPageView = 'Returning user page view',
  ReviewTrail = 'Review Trail',
  ReviewsPhotoTab = 'Reviews Photo Tab',
  RichProfile = 'Rich Profile',
  SaveFiltersTapped = 'Save Filters Tapped',
  SaveRecordingAsMap = 'Save Recording As Map',
  SaveTrail = 'Save Trail',
  SaveTrailAsMap = 'Save Trail As Map',
  SavedDownloadsNullStateGetProCTA = 'Saved Downloads Null State Get Pro CTA',
  SecondAppOpen = 'Second App Open',
  SecondPageView = 'Second Page View',
  SeeMoreActivities = 'See More Activities',
  SeeMoreCompleted = 'See More Completed',
  SeeMorePhotos = 'See More Photos',
  SeeMoreReviews = 'See More Reviews',
  SelectCommunityTab = 'Select Community Tab',
  SelectHistoryTab = 'Select History Tab',
  SelectMapLayer = 'Select Map Layer',
  SelectMapOverlay = 'Select Map Overlay',
  SelectPlanTab = 'Select Plan Tab',
  SelectProfileTab = 'Select Profile Tab',
  SelectRoute = 'Select Route',
  SelectSavedTab = 'Select Saved Tab',
  SendRoutesToGarmin = 'Send Routes To Garmin',
  ShareList = 'Share List',
  ShareLiveActivity = 'Share Live Activity',
  ShareTrail = 'Share Trail',
  StartRecording = 'Start Recording',
  SuggestTrailEdit = 'Suggest Trail Edit',
  TapAdExplanation = 'Tap Ad Explanation',
  TapMapDownloadNotification = 'Tap Map Download Notification',
  TapProIndicator = 'Tap Pro Indicator',
  TapProfileSocialConnections = 'Tap Profile Social Connections',
  TapProfileUpgradeCTA = 'Tap Profile Upgrade CTA',
  TrailCardDownload = 'Trail Card Download',
  TrailDetailDirections = 'Trail Detail Directions',
  TrailDetailDownload = 'Trail Detail Download',
  TrailDetailsWall3rdUniqueViewed = 'Trail Details Wall 3rd Unique Viewed',
  UnauthenticatedUserAppOpen = 'Unauthenticated User App Open',
  Upgrade = 'Upgrade',
  ViewMapOnWatch = 'View Map on Watch',
  ViewStaticMap = 'View Static Map',
  ViewTrailPhotos = 'View Trail Photos',
  WebPostSignup = 'Web Post Signup',
  WrongTurnAlertBannerEnabled = 'Wrong Turn Alert Banner Enabled',
  WrongTurnAlertNotificationsEnabled = 'Wrong Turn Alert Notifications Enabled',
  Directions = 'directions',
  DownloadList = 'download list',
  ParksEducationalSheet = 'parks educational sheet',
  SameTrailThreeTimesIn24Hours = 'same trail three times in 24 hours',
  SaveToList = 'save to list',
  ScrollAuth = 'scroll auth',
  SmartDownloadsAppOpen = 'smart downloads app open',
  SmartDownloadsLowBandwidthSliderVisual = 'smart downloads low bandwidth slider visual',
  SmartDownloadsNearTrailhead = 'smart downloads near trailhead',
  ThreeDMapsTapped = 'three d maps tapped',
  ThreeUniqueTrailsIn30Minutes = 'three unique trails in 30 minutes',
  TrailAddedToList = 'trail added to list',
  TrailConditionsGroundConditionsUpsellCardTapped = 'trail conditions ground conditions upsell card tapped',
  TrailConditionsMosquitoActivityUpsellCardTapped = 'trail conditions mosquito activity upsell card tapped',
  TrailConditionsWeatherAlongTheTrailUpsellCardTapped = 'trail conditions weather along the trail upsell card tapped',
  TrailDetailsMapViewScreenshot = 'trail details map view screenshot',
  TrailPageViewed = 'trail page viewed'
}

export default UpsellTrigger;

import SearchOrigin from '../enums/SearchOrigin';
import { getAmplitudeInstance } from '../amplitudeHelpers';

type logExploreSearchBoxClickedParams = {
  href?: string;
  path?: string;
  search_origin: SearchOrigin;
};

/**
 * User clicks/taps the search box
 * @param params - Attributes to log with the amplitude event
 * @param params.href - href of the webpage URL the user was on when they clicked/tapped the search box
 * @param params.path - path of the webpage URL the user was on when they clicked/tapped the search box
 * @param params.search_origin - Possible search box locations (homepage, search results)
 */
const logExploreSearchBoxClicked = ({ href, path, search_origin }: logExploreSearchBoxClickedParams, callback?: () => void) => {
  getAmplitudeInstance().logEvent('explore search box clicked', { href, path, 'search origin': search_origin }, callback);
};

export default logExploreSearchBoxClicked;

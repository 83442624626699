/**
 * The action link's location on the web page.
 */
enum TrailDetailsActionLocation {
  BottomFloatingBarDark = 'bottom floating bar dark',
  BottomFloatingBarLight = 'bottom floating bar light',
  ChatGptCard = 'chat gpt card',
  DownloadOverflowMenuButtonClicked = 'download overflow menu button clicked',
  GettingThere = 'getting there',
  GlobalNavProfileDropdown = 'global nav profile dropdown',
  HeaderBar = 'header bar',
  HeroImageExpandButton = 'hero image expand button',
  HeroImageInset = 'hero image inset',
  HitTheTrailHeaderOverflowLinks = 'hit the trail header overflow links',
  MapCard = 'map card',
  MidPageHitTheTrailModule = 'mid-page hit the trail module',
  MobileCtaBar = 'mobile cta bar',
  MobileView = 'mobile view',
  MobileWebDownloadOverflowLinks = 'mobile web download overflow links',
  Reviews = 'reviews',
  TrailDetailsExploreMap = 'trail details explore map'
}

export default TrailDetailsActionLocation;

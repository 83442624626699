'use client';

import { useEffect, useRef } from 'react';
import useApexDomain from '@alltrails/context/hooks/useApexDomain';
import Script from 'next/script';
import useAmplitudeAnalyticsSettings from '@alltrails/amplitude/hooks/useAmplitudeAnalyticsSettings';
import setUserProperties from '@alltrails/amplitude/utils/setUserProperties';
import { trackPageView } from '@alltrails/amplitude/utils/track';
import Cookies from 'js-cookie';
import logWebPageDwellTime from '@alltrails/amplitude/events/logWebPageDwellTime';
import { useExposureEvent } from '@alltrails/experiments';
import useIsMobileSizedScreen from '@alltrails/denali/hooks/useIsMobileSizedScreen';

type AmplitudeBrowserSdkProps = {
  logDwellTime?: boolean;
};

export default function AmplitudeBrowserSdk({ logDwellTime }: AmplitudeBrowserSdkProps) {
  const viewStart = useRef<number>();
  const amplitudeAnalyticsSettings = useAmplitudeAnalyticsSettings();
  const sendAppInstallOnSignUpModalDesktopExposure = useExposureEvent('web-growth-rm-app-install-on-auth-wall-desktop');
  const sendAppInstallOnSignUpModalMobileExposure = useExposureEvent('web-growth-rm-app-install-on-auth-wall-mobile');

  const userIdParam = amplitudeAnalyticsSettings.user_id ? ` "${String(amplitudeAnalyticsSettings.user_id)}"` : null;
  const internalDomain = useApexDomain();
  const isMobile = useIsMobileSizedScreen();

  useEffect(() => {
    setUserProperties({
      'appsflyer id': amplitudeAnalyticsSettings.user_properties['appsflyer id'],
      'browser type': amplitudeAnalyticsSettings.user_properties['browser type'],
      'can purchase subscription': amplitudeAnalyticsSettings.user_properties['can purchase subscription'],
      'group ids': amplitudeAnalyticsSettings.user_properties['group ids'],
      'special peak promo eligibility': amplitudeAnalyticsSettings.user_properties['special peak promo eligibility'],
      'trial eligibility': amplitudeAnalyticsSettings.user_properties['trial eligibility'],
      'user agent': navigator.userAgent,
      'user privacy': amplitudeAnalyticsSettings.user_properties['user privacy'],
      'user type': amplitudeAnalyticsSettings.user_properties['user type'],
      cid: Cookies.get('_ga'),
      fbc: Cookies.get('_fbc'),
      fbp: Cookies.get('_fbp'),
      epik: Cookies.get('_epik'),
      tinuiti_fpc: Cookies.get('at_tinuitifpc'),
      irclickid_expiration: Cookies.get('irclickid_expiration'),
      irclickid: Cookies.get('irclickid')
    });

    trackPageView();
    viewStart.current = Date.now();
  }, [amplitudeAnalyticsSettings.user_properties]);

  useEffect(() => {
    if (isMobile) {
      sendAppInstallOnSignUpModalMobileExposure();
    } else {
      sendAppInstallOnSignUpModalDesktopExposure();
    }
  }, [isMobile, sendAppInstallOnSignUpModalDesktopExposure, sendAppInstallOnSignUpModalMobileExposure]);

  useEffect(() => {
    const onVisibilityChange = () => {
      if (viewStart.current && document.visibilityState === 'hidden') {
        const dwellTime = Math.floor((Date.now() - viewStart.current) / 1000);
        logWebPageDwellTime({ dwell_time_seconds: dwellTime, href: window.location.href });
        window.amplitude.flush();
      } else if (document.visibilityState === 'visible') {
        viewStart.current = Date.now();
      }
    };

    if (logDwellTime && viewStart.current) {
      document.addEventListener('visibilitychange', onVisibilityChange);
    }

    return () => {
      if (logDwellTime) {
        document.removeEventListener('visibilitychange', onVisibilityChange);
      }
    };
  }, [logDwellTime]);

  return (
    <Script id="amplitude-script">
      {`
        !function(){"use strict";!function(e,t){var r=e.amplitude||{_q:[],_iq:{}};if(r.invoked)e.console&&console.error&&console.error("Amplitude snippet has been loaded.");else{var n=function(e,t){e.prototype[t]=function(){return this._q.push({name:t,args:Array.prototype.slice.call(arguments,0)}),this}},s=function(e,t,r){return function(n){e._q.push({name:t,args:Array.prototype.slice.call(r,0),resolve:n})}},o=function(e,t,r){e._q.push({name:t,args:Array.prototype.slice.call(r,0)})},i=function(e,t,r){e[t]=function(){if(r)return{promise:new Promise(s(e,t,Array.prototype.slice.call(arguments)))};o(e,t,Array.prototype.slice.call(arguments))}},a=function(e){for(var t=0;t<m.length;t++)i(e,m[t],!1);for(var r=0;r<g.length;r++)i(e,g[r],!0)};r.invoked=!0;var c=t.createElement("script");c.type="text/javascript",c.integrity="sha384-BWw9N39aN+4SdxZuwmRR0StXCLA+Bre4jR6bJt+pM1IqONNALC5rf25NkWMTyta5",c.crossOrigin="anonymous",c.async=!0,c.src="https://cdn.amplitude.com/libs/analytics-browser-2.9.3-min.js.gz",c.onload=function(){e.amplitude.runQueuedFunctions||console.log("[Amplitude] Error: could not load SDK")};var u=t.getElementsByTagName("script")[0];u.parentNode.insertBefore(c,u);for(var l=function(){return this._q=[],this},p=["add","append","clearAll","prepend","set","setOnce","unset","preInsert","postInsert","remove","getUserProperties"],d=0;d<p.length;d++)n(l,p[d]);r.Identify=l;for(var f=function(){return this._q=[],this},v=["getEventProperties","setProductId","setQuantity","setPrice","setRevenue","setRevenueType","setEventProperties"],y=0;y<v.length;y++)n(f,v[y]);r.Revenue=f;var m=["getDeviceId","setDeviceId","getSessionId","setSessionId","getUserId","setUserId","setOptOut","setTransport","reset","extendSession"],g=["init","add","remove","track","logEvent","identify","groupIdentify","setGroup","revenue","flush"];a(r),r.createInstance=function(e){return r._iq[e]={_q:[]},a(r._iq[e]),r._iq[e]},e.amplitude=r}}(window,document)}();

        amplitude.init("${process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY}", ${userIdParam}, {
          transport: 'beacon',
          defaultTracking: {
            // Opt out of all default tracking, except marketing attribution.
            attribution: {
              excludeReferrers: [/${internalDomain}$/, /appleid.apple\.com$/],
            },
            pageViews: false,
            sessions: false,
            formInteractions: false,
            fileDownloads: false
        }
      });
    `}
    </Script>
  );
}

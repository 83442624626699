enum SearchItemType {
  Address = 'address',
  Area = 'area',
  Attribute = 'attribute',
  Filter = 'filter',
  Guide = 'guide',
  GuideIndex = 'guide index',
  List = 'list',
  Map = 'map',
  Place = 'place',
  Poi = 'poi',
  Track = 'track',
  Trail = 'trail'
}

export default SearchItemType;

import SearchTab from '../enums/SearchTab';
import { getAmplitudeInstance } from '../amplitudeHelpers';

type logSearchTabSwitchedParams = {
  to_tab?: SearchTab;
};

/**
 * User switches between the 'location' and 'street address' tabs in the explore search on web. User can switch to street address by clicking tab or 'search by address' prompt.
 * @param params - Attributes to log with the amplitude event
 * @param params.to_tab - The tab that the user switched to
 */
const logSearchTabSwitched = ({ to_tab }: logSearchTabSwitchedParams, callback?: () => void) => {
  getAmplitudeInstance().logEvent('search tab switched', { 'to tab': to_tab }, callback);
};

export default logSearchTabSwitched;

/**
 * The specific type of POI as pulled from the POI Data Mapping List
 */
enum PointOfInterestType {
  AlpineHut = 'alpine_hut',
  ArcheologicalSite = 'archeological site',
  Attraction = 'attraction',
  Bathroom = 'bathroom',
  Bay = 'bay',
  Beach = 'beach',
  BeachHut = 'beach hut',
  BirdHide = 'bird hide',
  Building = 'building',
  Campsite = 'campsite',
  Canal = 'canal',
  Cape = 'cape',
  CaravanSite = 'caravan site',
  Castle = 'castle',
  Cave = 'cave',
  Church = 'church',
  Cliff = 'cliff',
  Coastline = 'coastline',
  Dam = 'dam',
  Dune = 'dune',
  Fort = 'fort',
  Garden = 'garden',
  Geyser = 'geyser',
  Glacier = 'glacier',
  Gorge = 'gorge',
  HistoricSite = 'historic site',
  HotSpring = 'hot spring',
  Hut = 'hut',
  Lagoon = 'lagoon',
  Lake = 'lake',
  Lock = 'lock',
  Manor = 'manor',
  Monastery = 'monastery',
  Monument = 'monument',
  Peak = 'peak',
  PhotoSpot = 'photo spot',
  PicnicArea = 'picnic area',
  Pond = 'pond',
  Rapids = 'rapids',
  Reservoir = 'reservoir',
  Ridge = 'ridge',
  River = 'river',
  RockShelter = 'rock shelter',
  Ruins = 'ruins',
  Spring = 'spring',
  Stream = 'stream',
  SunShelter = 'sun shelter',
  Tower = 'tower',
  Trail = 'trail',
  Valley = 'valley',
  Viewpoint = 'viewpoint',
  Volcano = 'volcano',
  Water = 'water',
  Waterfall = 'waterfall',
  WeatherShelter = 'weather shelter',
  WildernessHut = 'wilderness hut',
  WildlifeHide = 'wildlife hide'
}

export default PointOfInterestType;

/**
 * The link clicked in the dropdown of a section of the global nav bar.
 */
enum WebHeaderDropDownLink {
  AtPlus = 'at plus',
  Community = 'community',
  ContinueInApp = 'continue in app',
  CreateAList = 'create a list',
  CreateAMap = 'create a map',
  Favorites = 'favorites',
  GearShop = 'gear shop',
  GiveAtPlus = 'give at plus',
  HelpCenter = 'help center',
  LogOut = 'log out',
  MyMaps = 'my maps',
  NationalParkGuides = 'national park guides',
  NearbyTrails = 'nearby trails',
  Settings = 'settings',
  ShowProfile = 'show profile',
  ViewAllLists = 'view all lists'
}

export default WebHeaderDropDownLink;

import WebHeaderSection from '../enums/WebHeaderSection';
import { getAmplitudeInstance } from '../amplitudeHelpers';

type logWebHeaderHoveredParams = {
  web_header_position: number;
  web_header_section: WebHeaderSection;
};

/**
 * User hovers over a web section header on the global nav bar. July 2024 analytic event consolidation of WebHeaderShopHovered,WebHeaderSaveHovered, WebHeaderMyAccountHovered.
 * @param params - Attributes to log with the amplitude event
 * @param params.web_header_position - Integer denoting the zero-indexed position of the hovered section header in the global nav.
 * @param params.web_header_section - Title of section header in global nav that user hovered over.
 */
const logWebHeaderHovered = ({ web_header_position, web_header_section }: logWebHeaderHoveredParams, callback?: () => void) => {
  getAmplitudeInstance().logEvent(
    'web header hovered',
    { 'web header position': web_header_position, 'web header section': web_header_section },
    callback
  );
};

export default logWebHeaderHovered;

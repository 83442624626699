'use client';

import Logo from '@alltrails/denali/components/Logo';
import useIsMobileSizedScreen from '@alltrails/denali/hooks/useIsMobileSizedScreen';
import useUser from '@alltrails/context/hooks/useUser';
import logWebHeaderAllTrailsLogoTapped from '@alltrails/amplitude/events/logWebHeaderAllTrailsLogoTapped';
import Link from '@alltrails/denali/components/Link';
import styles from './styles/styles.module.scss';
import { HeaderBackground } from './types';

type LogoLinkProps = {
  background?: HeaderBackground;
};

const LogoLink = ({ background }: LogoLinkProps) => {
  const isMobile = useIsMobileSizedScreen();
  const user = useUser();

  return (
    <div className={styles.focusContainer}>
      <Link
        className={styles.logoLink}
        testId="header-logo"
        href="/?ref=header"
        prefetch={false}
        onClick={() => {
          logWebHeaderAllTrailsLogoTapped();
        }}
      >
        <Logo color={background === 'transparent-inverted' ? 'light' : 'dark'} size="sm" variant={user || isMobile ? 'symbol' : 'logomark'} />
      </Link>
    </div>
  );
};
export default LogoLink;

/**
 * Possible locations where a user may access the search/filter properties and apply a search.
 */
enum SearchOrigin {
  ExploreListView = 'explore list view',
  ExploreMapView = 'explore map view',
  GlobalNav = 'global nav',
  Homepage = 'homepage',
  LocationFeed = 'location feed',
  LocationPage = 'location-page',
  ParksPage = 'parks page',
  TrailPage = 'trail page'
}

export default SearchOrigin;

import ConsentDecision from '../enums/ConsentDecision';
import { getAmplitudeInstance } from '../amplitudeHelpers';

type logConsentDecisionMadeParams = {
  analytics?: ConsentDecision;
  essential: ConsentDecision;
  marketing?: ConsentDecision;
  optout?: ConsentDecision;
  personalization?: ConsentDecision;
  storage?: ConsentDecision;
};

/**
 * Web user is served an consent management banner via Osano. The frequency of being served the banner and the specific consent objects served (and how) will depend on IP address location
 * @param params - Attributes to log with the amplitude event
 * @param params.analytics - Identifies decision for analytics tracking
 * @param params.essential - Identifies decision for essential tracking, 'ACCEPT' is necessary
 * @param params.marketing - Identifies decision for marketing tracking
 * @param params.optout - Identifies decision for opt-out tracking, specific to IAB frameworks
 * @param params.personalization - Identifies decision for personalization tracking
 * @param params.storage - Identifies decision for storage tracking, specific to IAB frameworks
 */
const logConsentDecisionMade = (
  { analytics, essential, marketing, optout, personalization, storage }: logConsentDecisionMadeParams,
  callback?: () => void
) => {
  getAmplitudeInstance().logEvent('consent decision made', { analytics, essential, marketing, optout, personalization, storage }, callback);
};

export default logConsentDecisionMade;

import WebHeaderDropDownLink from '../enums/WebHeaderDropDownLink';
import WebHeaderSection from '../enums/WebHeaderSection';
import { getAmplitudeInstance } from '../amplitudeHelpers';

type logWebHeaderDropDownTappedParams = {
  item_name: WebHeaderDropDownLink;
  item_position: number;
  web_header_section: WebHeaderSection;
};

/**
 * After opening a web header menu in the global nav bar, user taps a link in the dropdown menu. July 2024 analytic event consolidation of WebHeaderShopDropDownTapped, WebHeaderMyAccountDropdownTapped, WebHeaderSavedDropDownTapped.
 * @param params - Attributes to log with the amplitude event
 * @param params.item_name - The link that the user tapped.
 * @param params.item_position - Integer denoting the zero-indexed position of the tapped item in the dropdown.
 * @param params.web_header_section - Title of section header in global nav.
 */
const logWebHeaderDropDownTapped = ({ item_name, item_position, web_header_section }: logWebHeaderDropDownTappedParams, callback?: () => void) => {
  getAmplitudeInstance().logEvent(
    'web header drop down tapped',
    { 'item name': item_name, 'item position': item_position, 'web header section': web_header_section },
    callback
  );
};

export default logWebHeaderDropDownTapped;

import { getAmplitudeInstance } from '../amplitudeHelpers';

type logWebPageDwellTimeParams = {
  dwell_time_seconds?: number;
  href?: string;
};

/**
 * How long a user spent on a web page. Intended to be used in experiments that have features which may impact SEO, not for ongoing tracking. Fires when visibility change event is fired. (https://developer.mozilla.org/en-US/docs/Web/API/Document/visibilitychange_event)
 * @param params - Attributes to log with the amplitude event
 * @param params.dwell_time_seconds - How long in seconds the user spend on the web page
 * @param params.href - Url of page where user scrolled
 */
const logWebPageDwellTime = ({ dwell_time_seconds, href }: logWebPageDwellTimeParams, callback?: () => void) => {
  getAmplitudeInstance().logEvent('web page dwell time', { 'dwell time seconds': dwell_time_seconds, href }, callback);
};

export default logWebPageDwellTime;

import { getAmplitudeInstance } from '../amplitudeHelpers';

/**
 * Google one-tap auth window displayed to user
 */
const logOneTapDisplayed = (callback?: () => void) => {
  getAmplitudeInstance().logEvent('one tap displayed', {}, callback);
};

export default logOneTapDisplayed;

import { getAmplitudeInstance } from '../amplitudeHelpers';

/**
 * A user taps on the AllTrails logo in the header on web.
 */
const logWebHeaderAllTrailsLogoTapped = (callback?: () => void) => {
  getAmplitudeInstance().logEvent('web header all trails logo tapped', {}, callback);
};

export default logWebHeaderAllTrailsLogoTapped;

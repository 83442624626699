import GuideSourceType from '../enums/GuideSourceType';
import { getAmplitudeInstance } from '../amplitudeHelpers';

type logGuideClickedParams = {
  guide_id: string;
  horizontal_index?: number;
  park_id?: string;
  source_id?: string;
  source_type: GuideSourceType;
  vertical_index?: number;
};

/**
 * User clicks to access a guide page from anywhere in the app
 * @param params - Attributes to log with the amplitude event
 * @param params.guide_id - The unique identifier of the guide that was clicked
 * @param params.horizontal_index - The 0-indexed horizontal position of the guide accessed, if guide page was accessed from a list
 * @param params.park_id - id of the park that the guide belongs to, if applicable, that was clicked on by the user
 * @param params.source_id - Unique identifier for the source of the guide page accessed, if applicable
 * @param params.source_type - The type of source that the guide page was accessed from
 * @param params.vertical_index - The 0-indexed vertical position of the guide accessed, if guide page was accessed from a 2-dimensional section list
 */
const logGuideClicked = (
  { guide_id, horizontal_index, park_id, source_id, source_type, vertical_index }: logGuideClickedParams,
  callback?: () => void
) => {
  getAmplitudeInstance().logEvent(
    'guide clicked',
    {
      'guide id': guide_id,
      'horizontal index': horizontal_index,
      'park id': park_id,
      'source id': source_id,
      'source type': source_type,
      'vertical index': vertical_index
    },
    callback
  );
};

export default logGuideClicked;

/**
 * Location in the app where a guide page can be accessed from
 */
enum GuideSourceType {
  Guide = 'guide',
  GuideIndex = 'guide index',
  GuideIndexPreview = 'guide index preview',
  LocationPage = 'location page',
  ParkPage = 'park page',
  Search = 'search'
}

export default GuideSourceType;

import GuideIndexLocation from '../enums/GuideIndexLocation';
import { getAmplitudeInstance } from '../amplitudeHelpers';

type logGuideIndexClickedParams = {
  guide_id?: string;
  guide_index_location: GuideIndexLocation;
  park_id?: string;
};

/**
 * User clicks to access the Guide index from anywhere in the app
 * @param params - Attributes to log with the amplitude event
 * @param params.guide_id - Unique identifier of the current guide page in view when the user accesses the guide index from the Recommendation section on a guide page
 * @param params.guide_index_location - The location in the app where the guide index was accessed from
 * @param params.park_id - id of the park that the guide index belongs to, if applicable.
 */
const logGuideIndexClicked = ({ guide_id, guide_index_location, park_id }: logGuideIndexClickedParams, callback?: () => void) => {
  getAmplitudeInstance().logEvent(
    'guide index clicked',
    { 'guide id': guide_id, 'guide index location': guide_index_location, 'park id': park_id },
    callback
  );
};

export default logGuideIndexClicked;

import type { AmplitudeClient } from 'amplitude-js';

const getMockBrowserSdkInstance = () => ({
  track: (eventName, eventProperties) => {},
  reset: () => {}
});

const getMockInstance = () => ({
  init: () => {},
  logEvent: () => {},
  getUserId: () => null,
  setUserId: () => {},
  regenerateDeviceId: () => {},
  setUserProperties: () => {}
});

const getAmplitudeBrowserSdkInstance = () => {
  let instance = getMockBrowserSdkInstance();
  if (window.amplitudeBrowserSdk) {
    instance = window.amplitudeBrowserSdk;
  }

  return instance;
};

// As we upgrade to the new Amplitude Browser SDK,
// we can use this shim to adopt the new sdk, and keep the analytics functions unchanged for now.
const getAmplitudeBrowserSdkShim = () => {
  const instanceShim = {
    logEvent: (eventName: string, eventProperties: Record<string, unknown>, callback?: () => void) => {
      // ignore the callback since we don't use it anywhere, and isn't supported in the browser sdk.
      window.amplitude.track(eventName, eventProperties);
    },
    regenerateDeviceId: window.amplitude.reset,
    setUserId: (userId: string | null) => {
      window.amplitude.setUserId(userId);
    }
  };

  return instanceShim;
};

const getAmplitudeInstance = () => {
  // "track" only exists in the new browser SDK. If it exists, return the browser sdk shim.
  if (window.amplitude && typeof window.amplitude.track === 'function') {
    return getAmplitudeBrowserSdkShim();
  }

  // getInstance should work seamlessly in alpha and production. In non-alpha|production
  // environments getInstance returns a mock instance where all methods are noops.
  // To enable Amplitude in development uncomment the second `api_key = ...` in the ApplicationController

  let instance = getMockInstance() as unknown as AmplitudeClient;

  if (window.amplitude) {
    instance = window.amplitude.getInstance();
  }

  return instance;
};

export { getAmplitudeInstance, getAmplitudeBrowserSdkInstance };

import { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import logExploreSearchBoxClicked from '@alltrails/amplitude/events/logExploreSearchBoxClicked';
import logGuideClicked from '@alltrails/amplitude/events/logGuideClicked';
import logSearchResultClicked from '@alltrails/amplitude/events/logSearchResultClicked';
import logSearchTabSwitched from '@alltrails/amplitude/events/logSearchTabSwitched';
import logGuideIndexClicked from '@alltrails/amplitude/events/logGuideIndexClicked';
import GuideSourceType from '@alltrails/amplitude/enums/GuideSourceType';
import SearchOrigin from '@alltrails/amplitude/enums/SearchOrigin';
import GuideIndexLocation from '@alltrails/amplitude/enums/GuideIndexLocation';
import { AlgoliaConfigs } from '@alltrails/shared/types/algoliaConfigs';
import { SearchType, AlgoliaResultType } from '@alltrails/shared/types/algoliaSearch';
import { useLanguageRegionCode } from '@alltrails/language/hooks/useLanguageRegionCode';
import useUser from '@alltrails/context/hooks/useUser';
import usePublicBaseUrl from '@alltrails/context/hooks/usePublicBaseUrl';
import useIsMobileSizedScreen from '@alltrails/denali/hooks/useIsMobileSizedScreen';
import { useExperiments, useExposureEvent } from '@alltrails/experiments';
import dynamic from 'next/dynamic';
import SearchTab from '@alltrails/amplitude/enums/SearchTab';
import SearchItemType from '@alltrails/amplitude/enums/SearchItemType';
import SearchSuggestionSelectionType from '@alltrails/amplitude/enums/SearchSuggestionSelectionType';
import { OnSelect, Results, SearchResultsEmptyState, SearchResultsTabBar } from '../../types/searchBoxTypes';
import {
  AlgoliaHit,
  AlgoliaSearchResult,
  AreaSearchResult,
  GuideMetadata,
  GuidesResult,
  NearbyResult,
  SearchRequestOptions,
  StreetAddressResult
} from '../../types/algoliaResultTypes';
import { algoliaHitToResult, getUrl, guideMetadataToSearchResult } from '../../utils/algoliaResultUtils';
import useRecentAlgoliaSearches from '../../hooks/useRecentAlgoliaSearches';
import useAlgoliaIndex from '../../hooks/useAlgoliaIndex';
import { getAnalyticsTags, getPoiType, getSearchItemType, getSearchSelectionType } from '../../utils/algoliaAnalyticsHelpers';
import CustomSearchBox, { CustomSearchBoxProps } from '../CustomSearchBox/CustomSearchBox';
import getStreetAddressResults from '../../utils/getStreetAddressResults';
import AlgoliaSearchResultContent from './AlgoliaSearchResultContent';
import styles from './styles/styles.module.scss';
import StreetAddressResultContent from './StreetAddressResultContent';
import NearbyResultContent from './NearbyResultContent';

const AlgoliaGuidesCarousel = dynamic(() => import('./AlgoliaGuidesCarousel'));

const locationTabId = 'location';
const streetAddressTabId = 'street-address';

type Result = AlgoliaSearchResult | GuidesResult | NearbyResult | StreetAddressResult;

export type AlgoliaSearchBoxProps = {
  configs: AlgoliaConfigs;
  getCurrentLatLng?: () => { lat: number; lng: number };
  hiddenResults?: string[];
  navigateOnSelect?: boolean;
  numResults?: number;
  onAddressSelect?: OnSelect<StreetAddressResult>;
  onNearbyClick?: () => void;
  onResultSelect?: OnSelect<AlgoliaSearchResult>;
  onSearch?: (response?: { queryID?: string }) => void;
  searchOrigin?: SearchOrigin;
  searchTypes: 'all' | Exclude<AlgoliaResultType, 'map' | 'track'>[] | ('map' | 'track')[];
  suppressAlgoliaAnalytics?: boolean;
  weightResults?: boolean;
} & Pick<
  CustomSearchBoxProps<AlgoliaSearchResult>,
  | 'className'
  | 'clearOnSelect'
  | 'debounceMs'
  | 'dropdownVariant'
  | 'onInputBlur'
  | 'onInputFocus'
  | 'placeholder'
  | 'size'
  | 'tertiaryElement'
  | 'testId'
  | 'variant'
  | 'value'
>;

const searchAll = (searchTypes: AlgoliaSearchBoxProps['searchTypes']) => searchTypes === 'all' || searchTypes.length === 0;
const nearbyResult: NearbyResult = { id: 'nearby', type: 'nearby', searchType: SearchType.Nearby };

const removeHiddenHits = (hits: AlgoliaHit[], hiddenObjectIds?: string[]) => {
  if (hiddenObjectIds?.length) {
    return hits.filter(hit => hit && !hiddenObjectIds.includes(hit.objectID));
  }
  return hits;
};

const AlgoliaSearchBox = ({
  configs,
  getCurrentLatLng,
  hiddenResults,
  navigateOnSelect = false,
  numResults = 50,
  onAddressSelect,
  onInputFocus: onInputFocusProp,
  onNearbyClick,
  onResultSelect,
  onSearch,
  searchOrigin,
  searchTypes = 'all',
  suppressAlgoliaAnalytics,
  weightResults,
  ...customSearchBoxProps
}: AlgoliaSearchBoxProps) => {
  const intl = useIntl();
  const languageRegionCode = useLanguageRegionCode();
  const user = useUser();
  const isMobile = useIsMobileSizedScreen();
  const { getRecentAlgoliaHits, addRecentAlgoliaHit } = useRecentAlgoliaSearches();
  // Holding off on tracking recent address searches via local storage due to privacy concerns
  // const { getRecentStreetAddressResults, addRecentStreetAddressResult } = useRecentStreetAddressSearches();
  const showNearbyResult = useMemo(() => !!onNearbyClick, [onNearbyClick]);
  const [results, setResults] = useState<Results<Result>>(showNearbyResult ? [nearbyResult] : []);
  const index = useAlgoliaIndex(configs, searchTypes);
  const [activeTab, setActiveTab] = useState(locationTabId);
  const [currentQuery, setCurrentQuery] = useState<string>('');
  const useNewSearch = useExperiments()['web-disco-rm-global-nav']?.value === 'treatment_b';
  const sendExposure = useExposureEvent('web-disco-rm-global-nav');
  const baseUrl = usePublicBaseUrl();

  useEffect(() => {
    sendExposure();
  }, [sendExposure]);

  // https://www.algolia.com/doc/api-reference/api-parameters/facetFilters/
  const facetFilters = useMemo(() => {
    const filters: string[][] = [];

    if (searchAll(searchTypes)) {
      filters.push(['type:-track', 'type:-map']);
    } else {
      const typedSearchTypes = searchTypes as AlgoliaResultType[]; // This gets type to never[] since there isn't overlap between the constant possibilities
      if (typedSearchTypes.length === 1) {
        filters.push([`type:${searchTypes[0]}`]);
      } else {
        filters.push(typedSearchTypes.map(type => `type:${type}`));
      }
    }

    filters.push(['type:-photospot', 'subtype:-photospot']);

    return filters;
  }, [searchTypes]);

  const updateLocationResults = useCallback(
    (searchType: SearchType, includeNearby: boolean, hits: AlgoliaHit[], hitsHeader?: string) => {
      const filteredHits = removeHiddenHits(
        hits.filter(hit => Boolean(hit)),
        hiddenResults
      );
      let filteredResults: Result[] = filteredHits.map(hit => algoliaHitToResult(hit, searchType));
      // If the first result is an area with guides, remove guides for that area from the remaining list of results
      // and move them immediately below the area result
      const includeGuides = searchAll(searchTypes) || (searchTypes as AlgoliaResultType[]).includes('guide');
      if (includeGuides && filteredResults.length && filteredResults[0].type === 'area' && filteredResults[0].guides?.length) {
        const areaResult = filteredResults[0] as AreaSearchResult;
        filteredResults = filteredResults.filter(result => !(result.type === 'guide' && result.area_id === areaResult.ID));
        if (areaResult.guides?.length === 1) {
          filteredResults.splice(1, 0, algoliaHitToResult({ ...areaResult.guides[0], type: 'guide' }, searchType));
        } else if ((areaResult.guides?.length || 0) > 1) {
          filteredResults.splice(1, 0, {
            type: 'guides',
            areaName: areaResult.short_name || areaResult.name,
            id: `${areaResult.ID}-guides`,
            guides: areaResult.guides as GuideMetadata[],
            containerClassName: styles.guidesContainer,
            searchType
          });
        }
      }
      const newResults: Results<Result> = [];
      if (includeNearby && showNearbyResult) {
        newResults.push(nearbyResult);
      }
      if (hits.length) {
        if (hitsHeader) {
          newResults.push({ text: hitsHeader });
        }
        newResults.push(...filteredResults);
      }
      setResults(newResults);
    },
    [hiddenResults, searchTypes, showNearbyResult]
  );

  const recentSearchesHeader = useMemo(() => intl.formatMessage({ defaultMessage: 'Recent searches' }), [intl]);

  const fetchLocationResults = useCallback(
    async (query: string) => {
      setCurrentQuery(query);
      const baseOptions: SearchRequestOptions = {
        hitsPerPage: numResults,
        facetFilters,
        ...(suppressAlgoliaAnalytics ? {} : { analyticsTags: getAnalyticsTags({ user, isMobile, searchOrigin, languageRegionCode, query }) })
      };

      const searchOptions: SearchRequestOptions = {
        ...baseOptions,
        getRankingInfo: true,
        aroundLatLngViaIP: true,
        // rank uniformly based on geo in these bands: 0-50mi, 50-200mi, 200+
        aroundPrecision: [
          { from: 0, value: 80000 },
          { from: 80000, value: 320000 },
          { from: 320000, value: 50000000 }
        ],
        aroundRadius: 'all'
      };

      if (query) {
        index
          .search(query, searchOptions)
          .then(response => {
            if (onSearch) {
              onSearch({ queryID: response.queryID });
            }
            updateLocationResults(SearchType.Suggestion, false, response.hits);
          })
          .catch(e => {
            window?.Bugsnag?.notify(e);
            updateLocationResults(SearchType.Suggestion, false, []);
          });
      } else {
        const recentHits = getRecentAlgoliaHits(searchTypes);
        if (recentHits.length) {
          index
            .getObjects(recentHits.map(hit => hit.objectID))
            .then(response => {
              updateLocationResults(SearchType.Recent, true, response.results as AlgoliaHit[], recentSearchesHeader);
            })
            .catch(e => {
              window?.Bugsnag?.notify(e);
              updateLocationResults(SearchType.Recent, true, []);
            });
        } else {
          updateLocationResults(SearchType.Nearby, true, []);
        }
      }
    },
    [
      facetFilters,
      getRecentAlgoliaHits,
      index,
      isMobile,
      languageRegionCode,
      numResults,
      onSearch,
      recentSearchesHeader,
      searchOrigin,
      searchTypes,
      suppressAlgoliaAnalytics,
      updateLocationResults,
      user
    ]
  );

  const fetchStreetAddressResults = useCallback(
    async (query: string) => {
      setCurrentQuery(query);

      if (query) {
        const latLng = getCurrentLatLng?.();
        const lat = latLng?.lat;
        const lng = latLng?.lng;
        const { searchResults } = await getStreetAddressResults(query, lat, lng);
        setResults(
          searchResults.map<StreetAddressResult>(result => {
            const { latitude, longitude } = result.geo;
            return { ...result, id: [longitude, latitude].join(',') };
          })
        );
      } else {
        // const recents = getRecentStreetAddressResults();
        // if (recents?.length) {
        //   setResults([{ text: recentSearchesHeader }, ...recents]);
        // }
        setResults([]);
      }
    },
    [getCurrentLatLng]
  );

  const fetchResultsRequest = useMemo(() => {
    if (activeTab === streetAddressTabId) {
      return fetchStreetAddressResults;
    }
    return fetchLocationResults;
  }, [activeTab, fetchLocationResults, fetchStreetAddressResults]);

  const onGuideSelect = useCallback(
    (guide: GuideMetadata, index: number, searchType?: SearchType) => {
      logGuideClicked({
        guide_id: String(guide.ID),
        source_type: GuideSourceType.Search,
        horizontal_index: index,
        vertical_index: 1 // The guides carousel always renders as the 2nd element
      });

      const result = guideMetadataToSearchResult(guide, searchType);
      if (onResultSelect) {
        onResultSelect(result);
      }
      if (navigateOnSelect) {
        window.location.assign(`${baseUrl}${getUrl(result, languageRegionCode, useNewSearch)}`);
      }
    },
    [baseUrl, languageRegionCode, navigateOnSelect, onResultSelect, useNewSearch]
  );

  const onSelect: OnSelect<Result> = useCallback(
    (result, additionalInfo) => {
      if (!result) {
        return;
      }
      if (result.type === 'nearby') {
        onNearbyClick?.();
      } else if (result.type === 'guides') {
        // do nothing
      } else if (result.type === 'address') {
        // addRecentStreetAddressResult(result);
        if (searchOrigin) {
          logSearchResultClicked({
            click_position: additionalInfo?.index,
            item_type: SearchItemType.Address,
            location: searchOrigin,
            query_length: additionalInfo?.query?.length,
            selection_type: SearchSuggestionSelectionType.SearchSuggestion
          });
        }
        onAddressSelect?.(result, additionalInfo);
      } else {
        addRecentAlgoliaHit({ objectID: result.objectID, type: result.type });
        if (searchOrigin) {
          logSearchResultClicked({
            click_position: additionalInfo?.index,
            content_id: result.id,
            item_type: getSearchItemType(result.type),
            location: searchOrigin,
            poi_type: result.type === 'poi' ? getPoiType(result) : undefined,
            query_length: additionalInfo?.query?.length,
            selection_type: getSearchSelectionType(result.searchType)
          });
        }
        if (result.type === 'guide') {
          logGuideClicked({
            guide_id: String(result.ID),
            source_type: GuideSourceType.Search,
            vertical_index: additionalInfo?.index
          });
        }
        if (result.type === 'page' && result.subtype === 'guides') {
          logGuideIndexClicked({
            guide_index_location: GuideIndexLocation.Search
          });
        }
        if (onResultSelect) {
          onResultSelect(result, additionalInfo);
        }
        if (navigateOnSelect && typeof window !== 'undefined') {
          window.location.assign(`${baseUrl}${getUrl(result, languageRegionCode, useNewSearch)}`);
        }
      }
    },
    [onNearbyClick, onAddressSelect, addRecentAlgoliaHit, searchOrigin, onResultSelect, navigateOnSelect, baseUrl, languageRegionCode, useNewSearch]
  );

  const onInputFocus = useCallback(() => {
    if (searchOrigin) {
      const { href, pathname } = window.location;
      logExploreSearchBoxClicked({ search_origin: searchOrigin, href, path: pathname });
    }
    onInputFocusProp?.();
  }, [onInputFocusProp, searchOrigin]);

  const searchResultsTabBar = useMemo<SearchResultsTabBar | undefined>(() => {
    if (onAddressSelect) {
      return {
        activeTab,
        onChange: id => {
          logSearchTabSwitched({ to_tab: id === streetAddressTabId ? SearchTab.StreetAddress : SearchTab.Location });
          setActiveTab(id);
        },
        tabs: [
          { id: locationTabId, label: intl.formatMessage({ defaultMessage: 'Location' }), testId: `search-tab-${locationTabId}` },
          { id: streetAddressTabId, label: intl.formatMessage({ defaultMessage: 'Street address' }), testId: `search-tab-${streetAddressTabId}` }
        ]
      };
    }
    return undefined;
  }, [activeTab, intl, onAddressSelect]);

  const renderResultContent = useCallback(
    (result: Result) => {
      switch (result.type) {
        case 'nearby':
          return <NearbyResultContent result={result} />;
        case 'guides':
          return <AlgoliaGuidesCarousel onGuideSelect={onGuideSelect} result={result} />;
        case 'address':
          return <StreetAddressResultContent query={currentQuery} result={result} />;
        default:
          return (
            <AlgoliaSearchResultContent
              hideResultType={Array.isArray(searchTypes) && searchTypes.length === 1}
              result={result}
              url={`${baseUrl}${getUrl(result, languageRegionCode, useNewSearch)}`}
              query={currentQuery}
            />
          );
      }
    },
    [baseUrl, currentQuery, languageRegionCode, onGuideSelect, searchTypes, useNewSearch]
  );

  const emptyState = useMemo<SearchResultsEmptyState | undefined>(() => {
    if (onAddressSelect) {
      if (activeTab === streetAddressTabId) {
        return {
          primary: <FormattedMessage defaultMessage="Search by address" />,
          secondary: <FormattedMessage defaultMessage="Find trails near home, a hotel, or any address" />
        };
      }
      return {
        primary: <FormattedMessage defaultMessage="Search by location" />,
        secondary: <FormattedMessage defaultMessage="Search by city, park, or trail name" />
      };
    }
    return undefined;
  }, [activeTab, onAddressSelect]);

  return (
    <CustomSearchBox
      {...customSearchBoxProps}
      emptyState={emptyState}
      fetchResultsRequest={fetchResultsRequest}
      onInputFocus={onInputFocus}
      onSelect={onSelect}
      renderResultContent={renderResultContent}
      results={results}
      searchResultsTabBar={searchResultsTabBar}
    />
  );
};

export default AlgoliaSearchBox;

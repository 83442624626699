import TrailDetailsAction from '../enums/TrailDetailsAction';
import TrailDetailsActionLocation from '../enums/TrailDetailsActionLocation';
import { getAmplitudeInstance } from '../amplitudeHelpers';

type logQRModalShownParams = {
  had_app_when_web_to_app_started?: boolean;
  module_location?: TrailDetailsActionLocation;
  was_plus_when_web_to_app_started?: boolean;
  web_to_app_action?: TrailDetailsAction;
};

/**
 * User views a QR code on web to enable an action in the app.
 * @param params - Attributes to log with the amplitude event
 * @param params.had_app_when_web_to_app_started - TRUE if the user had the app successfully downloaded when they started the web to app transfer.
 * @param params.module_location - The location of the module the user interacted with to transfer to their app.
 * @param params.was_plus_when_web_to_app_started - TRUE if the user was a plus user when they started the web to app transfer.
 * @param params.web_to_app_action - The action that the user wants to transfer to their app.
 */
const logQRModalShown = (
  { had_app_when_web_to_app_started, module_location, was_plus_when_web_to_app_started, web_to_app_action }: logQRModalShownParams,
  callback?: () => void
) => {
  getAmplitudeInstance().logEvent(
    'qr modal shown',
    {
      'had app when web to app started': had_app_when_web_to_app_started,
      'module location': module_location,
      'was plus when web to app started': was_plus_when_web_to_app_started,
      'web to app action': web_to_app_action
    },
    callback
  );
};

export default logQRModalShown;

import AuthenticationType from '@alltrails/amplitude/enums/AuthenticationType';
import type User from '@alltrails/core/types/User';
import { authSuccessCallback } from './AuthHandlers';

const appleRedirectHandler = (user: User) => {
  const params = new URLSearchParams(window.location.search);
  const appleOAuth = params.get('apple_oauth');
  const isCollabListInvite = params.get('is_collab_list_invite') === 'true';
  if (appleOAuth) {
    const is_sign_up = appleOAuth === 'signup';
    authSuccessCallback({ is_sign_up, user }, AuthenticationType.Apple, isCollabListInvite);
    const search = params.toString();
    window.history.replaceState(null, '', window.location.pathname + (search !== '' ? `?${search}` : ''));
  }
};

export { appleRedirectHandler };
